.nav_buttons {
    /* width:100%;    */
    /* border: solid 1px rgb(156, 160, 51);     */
    display: flex;
    flex-grow: 2;
    justify-content: center;    
    height: 100px;
    
}
.head{
    max-width: 1200px;
    justify-content: space-between;
    display: flex;   
    flex-wrap: wrap; 
    position: fixed;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;  
    z-index:10;
  }

.background_grey{
    background-color: rgb(234, 234, 234);
    width: 100%;
    height: 100px;   
    position: absolute;
    margin: 0 auto;
    left:0;
    z-index: -1;
    /* box-shadow:1px -1px 1px rgba(139, 139, 139, 0.734); */
}
.content{
    margin:0 20px;  
}
.logo a{
    border: none;   
}
.logo a.active{
    border: none;   
}
.nav_buttons a {
    margin-top: 20px;
    text-decoration: none;
    color: black;  
    border-right: 2px solid rgb(234, 234, 234);     
}
.nav_buttons a.active{
    background-color: rgb(255, 255, 255);
    border-radius: 5px 5px 0 0 ;
    border-right: 2px solid rgba(204, 202, 202, 0.593);
}
.link{
    padding: 0 30px;
    margin: auto 0;
    line-height: 80px;    
}
.link:hover{
    color: orange;
}
