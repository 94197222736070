.background_white{
    background-color: rgb(255, 255, 255);    
    position: fixed;
    margin: 0 auto;
    left:0;
    right:0;
    top:0;
    height: 100px; 
    width: 100%;  
    z-index: -2; 
}
.logo{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-grow: 1;
    padding: 20px;
}
.logoDK{
    min-width: 198px;
    /* height: 60px; */
    /* position: fixed;     */
}
.logoDK img:hover{
    cursor: pointer;
}
.smaller{
    height: 60px;
    width: 60px;
    transition-duration: 200ms;
    outline: 0;
}
.normal{
    height: 198px;
    width: 198px;    
    transition-duration: 200ms;
}
