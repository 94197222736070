.mainFooter{
    max-width: 1200px;   
    justify-content: space-around;   
    background-color:  rgb(234, 234, 234);
    margin: 0 auto;    
    bottom: 0;
    left: 0;
    right: 0;  
    color: grey;
    border-top: 1px solid grey;
}
.section{
    flex-grow: 1;
    padding: 10px;
    text-align: left;
    max-width: 200px;
    margin-bottom: 10px;
}
.con_contact{
    display: flex;    
    flex-grow: 1;    
    text-align: left;
    max-width: 600px;
    line-height: 24px;
}
.sectionContact{  
    display: flex;
    align-items: center;  
    padding: 3px 10px 3px;
    text-align: left;
    max-width: 600px;
    max-height: 31px;
    /* line-height: 20px;     */
}
.copy:hover{
    cursor: pointer;
}
span a {
    /* text-decoration: none; */
    color:grey;
}