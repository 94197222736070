.office{
    gap:20px;
}
.officeContact{   
    text-align: left;
    flex-grow: 1;
    
}
.officeContact2{
    flex-grow: 2;
    padding-top: 25px;
    max-width: 600px;
}
.localization{
    max-width: 300px;
    margin:0 auto;
    padding-top:20px;
}
.localization hr{
    background: linear-gradient(to right, rgba(11, 74, 209, 0.316),rgb(11, 74, 209),rgba(159, 154, 241, 0.105))
}
.map{
    border:0;
    max-width: 1200px;
    min-height: 300px;
    transition-duration: 300ms;
}
.map:hover{
    transition-duration: 300ms;
    box-shadow: 0 0 8px 2px rgba(89, 89, 89, 0.526);
}
.lin{
    color: white;
}