@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
  font: normal normal normal 15px Montserrat;
}

header {
  /* display: block; */
  margin: 0 auto;
  /* max-width: 1200px; */
}

footer {
 /* padding-top: 20px; */
}

.fontBig {
  font: normal normal normal 20px Montserrat;
}

.fontStandard {
  font: normal normal normal 15px Montserrat;
}

.fontSmall {
  font: normal normal normal 14px Montserrat;
}

.fontVerySmall {
  font: normal normal normal 10px Montserrat;
}

.colorBlue {
  color: rgb(37, 104, 179)
}

.colorBlueStrong {
  color: rgb(37, 104, 179);
  font-weight: bold;
}

.colorWhite {
  color: white;
}

.colorWhiteStrong {
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.App {
  /* max-width: 1200px; */
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  /* align-items: center; */
  text-align: center;
  min-height: 100vh;
}

.cont_horizontal {
  display: flex;
  flex-direction: column;
}

.cont_vertical {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.buttonUp {
  position: fixed;
  bottom: 30px;
  right: 10%;
  cursor: pointer;
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL'0,
    'wght'400,
    'GRAD'0,
    'opsz'35
}

.visible {
  font-size: 60px;
  opacity: 40%;
  transition-duration: 1000ms;
}

.novisible {
  font-size: 60px;
  opacity: 0%;
}

.page {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  margin: 0 auto;
  margin-top: 20px;
  max-width: 1200px;
}

section {
  /* max-width: 1200px; */
  overflow: hidden;
  display: flex;  
  flex-grow: 1;
  padding-bottom: 20px;
}

hr {
  margin-left: 0;
  border: 0;
  height: 1px;
  max-width: 500px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.588), rgb(255, 255, 255), rgba(255, 255, 255, 0.105))
}

.indent {
  text-indent: 20px;
}