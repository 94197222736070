/* html: <div class="load"></div> */
.load{
    /* position:relative; */
    width:50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 7px #4465da;
     animation: anime 1s infinite  cubic-bezier(0.4,0.8,0.8,0.4)
}

.load::before{
    content:"";
    position: absolute;
    width:52px;
    height:52px;
    border-radius: 50%;
    background: radial-gradient(at 0 0 , rgb(255, 255, 255) 35%, rgba(252, 252, 252, 0));
    top:-1px;
    left:-1px;
    transform-origin: 0px 26px;
   
}
@keyframes anime {
    0% {
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
