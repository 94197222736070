.home{
    gap: 20px;
    justify-content: center;    
    
}
.shortNote{
    flex-grow: 1;
    padding: 20px;
    text-align: left;
    min-width: 200px;  
    transition-duration: 300ms;  
    background: linear-gradient(to bottom right,rgb(38, 102, 177),rgb(11, 193, 231))
}
.shortNote:hover{
    box-shadow: 0 0 8px 2px rgba(89, 89, 89, 0.526);
    transition-duration: 300ms;
}
.contentLeft{
    max-width: 400px;
}
.shortNoteRight{  
    z-index: 0;  
    flex-grow: 10;
    text-align: left;
    min-width: 200px;    
    /* padding: 20px; */
    transition-duration: 300ms;
    background: linear-gradient(to bottom right,rgb(38, 102, 177),rgb(11, 193, 231))
}
.shortNoteRight:hover{
    box-shadow: 0 0 8px 2px rgba(89, 89, 89, 0.526);
    transition-duration: 300ms;    

}

.linkRight a{
    text-decoration: underline;
    color: white;    
}
.linkRight a:hover{
    cursor: pointer;
}
.linkRight{
    text-align: right; 
}
.desk{
    height:300px;
    position: relative;
    overflow: hidden;
}
.desk img{
    /* width:300px; */
    z-index:0;
    position: absolute;  
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    transition-duration: 300ms;  
}
.desk img:hover{
    z-index:0;
    position: absolute;  
    top:50%;
    left:50%;
    transform: translate(-50%,-50%) scale(1.1); 
    transition-duration: 300ms; 
}